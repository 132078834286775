import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import classNames from 'classnames'

interface Props {
  className?: string
  children: React.ReactNode
}

const isOverflown = ({ clientHeight = 0, scrollHeight = 0 } = {}) => scrollHeight > clientHeight

export const EloModalBody = forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>(
  ({ children, className }, ref) => {
    const containerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>
    const [isOverflow, setIsOverflow] = useState(false)

    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
        setIsOverflow(isOverflown(containerRef.current || {}))
      })

      resizeObserver.observe(containerRef.current)

      return () => {
        containerRef.current && resizeObserver.unobserve(containerRef.current)
      }
    }, [containerRef.current])

    useImperativeHandle(ref, () => containerRef.current)

    return (
      <div className={classNames('elo-ui-modal__body', className, { overflow: isOverflow })} ref={containerRef}>
        {children}
      </div>
    )
  }
)
