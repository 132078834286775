import React, { useCallback, MouseEvent } from 'react'
import classNames from 'classnames'
import { TEST_IDS } from '../../types'

import './elo-button.scss'

export const BUTTON_VARIANTS = ['primary', 'secondary', 'highlight', 'danger', 'ghost'] as const

export type ButtonVariantType = (typeof BUTTON_VARIANTS)[number]

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  as?: React.ElementType
  children?: React.ReactNode
  variant?: ButtonVariantType
  size?: 'small' | 'medium' | 'large' | 'x-large'
  fullWidth?: boolean
  disabled?: boolean
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  className?: string
  dataTestId?: string
  counter?: number
  onClick?: (e: MouseEvent) => void
  elevation?: '1' | '2'
  [key: string]: any
}

export const EloButton: React.FC<Props> = ({
  onClick = null,
  variant = 'primary',
  size = 'large',
  leftIcon = null,
  rightIcon = null,
  counter,
  as: Component = 'button',
  disabled = false,
  fullWidth = false,
  children,
  className,
  ariaLabel = 'Button',
  dataTestId = TEST_IDS.button,
  elevation,
  ...restProps
}) => {
  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (disabled) return

      if (onClick) {
        return onClick(e)
      }
    },
    [disabled, onClick]
  )
  const classes = classNames(className, 'elo-button', `elo-button--${variant}`, `elo-button--${size}`, {
    disabled,
    'elo-button--with-text': !!children,
    'elo-button--with-icon': !!leftIcon || !!rightIcon,
    'elo-button--full-width': fullWidth,
    [`elo-button--elevation-${elevation}`]: variant !== 'ghost',
  })

  return (
    <Component
      onClick={handleClick}
      className={classes}
      tabIndex={disabled ? -1 : 0}
      aria-disabled={disabled}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      {...restProps}
    >
      {leftIcon}
      {children}
      {rightIcon}
      {counter && <div className='elo-button__counter'>{counter}</div>}
    </Component>
  )
}
