import React from 'react'
import classNames from 'classnames'

import { isString } from '@elo-kit/utils/validators.utils'

import { InfoTooltip } from '@elo-kit/components/info-tooltip/InfoTooltip'
import { useI18n } from '@elo-kit/components/i18n/i18n'

import './radio-field.scss'

interface Props {
  id?: string | number | boolean
  errors?: React.ReactNode
  isInvalid?: boolean
  style?: object
  tooltipId?: string
  tooltipTitle?: boolean
  value?: string | number | boolean
  className?: string
  label?: React.ReactNode
  disabled?: boolean
  hidden?: boolean
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void
  onClick?: () => void
  dataTestId?: string
  bodyComponent?: boolean
  body?: React.ReactNode
  tooltipPlacement?: string
  name?: string
  centered?: boolean
}

export const RadioField = (props: Props) => {
  const I18n = useI18n()
  const {
    className,
    disabled = false,
    errors,
    id,
    isInvalid,
    label,
    style,
    tooltipId,
    tooltipTitle,
    value,
    hidden = false,
    bodyComponent,
    body,
    tooltipPlacement,
    centered,
    ...restProps
  } = props

  const checked = String(id) === String(value)

  const fieldClassNames = classNames(
    'field radio-field',
    {
      'field--disabled': disabled && !hidden,
    },
    className
  )

  const controlClassNames = classNames('field__control radio-field__control', {
    'radio-field__control--active': checked || hidden,
    'radio-field__control--without-left-padding': hidden,
  })

  const labelProps: { className: string; htmlFor?: string } = {
    className: controlClassNames,
  }

  if (!!id && typeof id !== 'boolean') {
    labelProps.htmlFor = String(id)
  }

  const tooltipTitles = {
    privacy_public: I18n.t('react.cabinet.help_icon.privacy_public.title'),
    privacy_private: I18n.t('react.cabinet.help_icon.privacy_private.title'),
    product_assignments_lesson_finish: I18n.t('react.cabinet.help_icon.product_assignments_lesson_finish.title'),
    product_assignments_quiz_success: I18n.t('react.cabinet.help_icon.product_assignments_quiz_success.title'),
  }
  const tooltipContent = {
    consent_form: I18n.t('react.cabinet.help_icon.consent_form.content'),
    consent_external: I18n.t('react.cabinet.help_icon.consent_external.content'),
    consent_no_cookies: I18n.t('react.cabinet.help_icon.consent_no_cookies.content'),
    course_duration: I18n.t('react.cabinet.help_icon.course_duration.content'),
    course_end_date: I18n.t('react.cabinet.help_icon.course_end_date.content'),
    privacy_public: I18n.t('react.cabinet.help_icon.privacy_public.content'),
    privacy_private: I18n.t('react.cabinet.help_icon.privacy_private.content'),
    sole_trader: I18n.t('react.cabinet.help_icon.sole_trader.content'),
    business: I18n.t('react.cabinet.help_icon.business.content'),
    organization: I18n.t('react.cabinet.help_icon.organization.content'),
    product_assignments_lesson_finish: I18n.t('react.cabinet.help_icon.product_assignments_lesson_finish.content'),
    product_assignments_quiz_success: I18n.t('react.cabinet.help_icon.product_assignments_quiz_success.content'),
    iap_content_settings_content: I18n.t('react.cabinet.help_icon.iap_content_settings_content.content'),
    iap_content_settings_lesson_link: I18n.t('react.cabinet.help_icon.iap_content_settings_lesson_link.content'),
    custom_params: I18n.t('react.cabinet.help_icon.custom_params.content'),
    // prettier-ignore
    block_conversion_tools_countdown_counter: I18n.t('react.cabinet.help_icon.block_conversion_tools_countdown_counter.content'),
    block_conversion_tools_modal_window: I18n.t('react.cabinet.help_icon.block_conversion_tools_modal_window.content'),
    block_conversion_tools_progress_bar: I18n.t('react.cabinet.help_icon.block_conversion_tools_progress_bar.content'),
  }
  return (
    <div className={fieldClassNames} data-testid='radio-field'>
      {isInvalid && (
        <div className='field__error'>
          {errors}
          <i className='fas fa-exclamation-circle' />
        </div>
      )}

      <label {...labelProps}>
        {!hidden && (
          <>
            <input
              type='radio'
              {...{
                ...restProps,
                checked,
                disabled,
                id: String(id),
              }}
            />
            <span className='custom-check-mark' style={checked ? style : {}}>
              <i className='boom' />
            </span>
          </>
        )}

        {isString(label) ? <span dangerouslySetInnerHTML={{ __html: String(label) }} /> : label}

        {tooltipId && (
          <InfoTooltip
            id={`${tooltipId}_popover`}
            title={tooltipTitle && tooltipTitles[tooltipId]}
            body={bodyComponent ? body : tooltipContent[tooltipId]}
            component={bodyComponent}
            placement={tooltipPlacement}
          />
        )}
      </label>
    </div>
  )
}

RadioField.displayName = 'RadioField'
