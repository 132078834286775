import React, { MouseEvent } from 'react'

export enum TEST_IDS {
  input = 'elo-input',
  text = 'elo-text',
  button = 'elo-button',
  link = 'elo-link',
  toggle = 'elo-toggle',
  checkbox = 'elo-checkbox',
  switch = 'elo-switch',
  radioButton = 'elo-radio-button',
  dateTimeInput = 'elo-date-time-input',
  inputWithColorPicker = 'elo-input-with-color-picker',
  colorPickerInput = 'elo-color-picker-input',
  colorPicker = 'elo-color-picker',
  colorPickerSwatch = 'elo-color-picker-swatch-value',
  topBar = 'elo-top-bar',
  topBarProfile = 'elo-top-bar-profile',
  topBarProfileMenu = 'elo-top-bar-profile-menu',
  topBarNotifications = 'elo-top-bar-notifications',
  topBarNotificationsMenu = 'elo-top-bar-notifications-menu',
  statusBadge = 'elo-status-badge',
}

export enum PRODUCT_TYPES {
  course = 'course',
  digital = 'service',
  download = 'download',
  membership = 'membership',
  eventTickets = 'event',
  certificate = 'certificate',
  licenseKey = 'license_key',
  bundle = 'bundle',
}

export interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  as?: React.ElementType
  onClick?: (e: MouseEvent) => void
  disabled?: boolean
  external?: boolean
  children?: React.ReactNode
  className?: string
  dataTestId?: string
  highlight?: boolean
  light?: boolean
  [key: string]: any
}

export interface TooltipProps {
  className?: string
  placement?: 'top' | 'left' | 'right' | 'bottom'
  title?: string
  children?: React.ReactNode
  content?: JSX.Element | React.ReactNode | (() => JSX.Element)
  show?: boolean
  wrapperClassName?: string
  body?: React.ReactNode
  pointerPosition?: 'start' | 'center' | 'end'
  disabled?: boolean
  hideDelay?: number
  showDelay?: number
}

export interface SidebarProps {
  rootLink?: string
  logo?: string | React.ReactNode
  footerLogo?: string | React.ReactNode
  items?: SidebarItemProps[]
  isMobile: boolean
  isOpen: boolean
  copyright?: string
  onItemCLick?: (item: SidebarItemProps) => void
  onOpenCLick?: (isOpen: boolean) => void
  activeItem?: SidebarItemProps
  openedItem?: SidebarItemProps
  footerContent?: React.ReactNode
}

export interface SidebarItemProps {
  id: string
  link?: string
  href?: string
  target?: string
  icon?: React.ReactNode
  label: string
  badge?: {
    label: string
    icon: React.ReactNode | null
  }
  submenu?: SidebarItemProps[]
  isHidden?: boolean | ((dependencies: any) => boolean)
  isActive?: (dependencies: any) => boolean
  onItemCLick?: (item: SidebarItemProps) => void
  onOpenCLick?: (item: SidebarItemProps) => void
  activeItem?: SidebarItemProps
  openedItem?: SidebarItemProps
}
